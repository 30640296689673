import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import cx from "classnames";

import Button from "../../components/Button";
import { useCommonStyles } from "../../../../utils/loan-application.styles";
import { borderColor } from "../../../../scss/colors.scss";

const useStyles = makeStyles({
  card: {
    padding: "16px 24px",
    border: `1px solid ${borderColor}`,
    borderRadius: "5px",
  },
  editButton: {
    padding: "5px 15px",
  },
});
const CompletedCard = ({ data, onEdit }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  return (
    <Box
      classes={{ root: classes.card }}
      display="flex"
      flexDirection="column"
      gridRowGap="16px"
      width="100%"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {data.icon}
        <Typography
          classes={{
            root: cx(commonClasses.fontSize20, commonClasses.fontWeightBold),
          }}
        >
          {data.label}
        </Typography>
        <Button classes={classes.editButton} onClick={() => onEdit(data.value)}>
          Edit
        </Button>
      </Box>
      <Box>
        <Typography
          classes={{
            root: commonClasses.fontSize18,
          }}
        >
          {data.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default CompletedCard;
